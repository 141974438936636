import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import './membershipapplication.css'
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha'
import { useForm } from 'react-hook-form'
import {
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import { isEmpty } from 'lodash'



const UpdateMembershipForm = (props) => {

  const data = useStaticQuery(graphql`
    {
      allStrapiMembershipApplication {
        nodes {
          MembershipApplicationPageBackgroundColor
          AbovePhoneNumberIs
          Address1
          Address2
          DualMember
          EmailAddress
          HomeNumber
          HowWillYouPay
          IRequestASponsoredMembership
          IWillPayForMyOwnAndPlusSponsorAnotherMembership
          IWillPayForMyOwnMembership
          InterpretingCredentials
          IowaLicenseNumber
          MembershipCategoryDesired
          PhoneNumber
          NewMember
          PreferredPhoneIsA
          ReturningMember
          StateAffiliateMember
          StudentMember
          SupportingMember
          SupportingOrganization
          TextOnly
          WhichAreYou
          WorkNumber
          ZIP
          city
          firstName
          lastName
          longText1
          longText2
          mobileNumber
          state
          video
          voiceAndText
          voiceonly
          MembershipApplicationTitle {
            TitleAlignment
            TitleFontColor
            TitleFontFamily
            TitleFontSize
            TitleFontWeight
            TitleName
          }
        }
      }
    }
  `)


const Data1 = {
  username : props.passData.username,
  firstName: props.passData.firstName,
  lastName: props.passData.lastName,
  email: props.passData.email,
  IowaLicenseNumber: props.passData.licenseNumber,
  InterpretingCredentials: props.passData.interpreterCredentials,
  address: props.passData.address,
  city: props.passData.city,
  state: props.passData.state,
  zipcode: props.passData.zipcode,
  PhoneNumber: props.passData.phoneNumber,
  opt:props.passData.opt
}

  const {
  register,
  handleSubmit,
  formState: { errors },
} = useForm({ defaultValues: Data1 })


  var membership = data.allStrapiMembershipApplication.nodes
  const dynamicNode = membership[0].MembershipApplicationTitle[0]
  const star = '*'

//   const captchaRef1 = useRef(null)

//   const handleCaptcha1 = e => {
//     const captcha = captchaRef1.current.value
//     if (validateCaptcha(captcha)) {
//       return true
//     } else {
//       return false
//     }
//   }

//   useEffect(() => {
//     loadCaptchaEnginge(6)
//   })

  const createData = e => {
    e.preventDefault()

  var optOut = null
  var checkBox = document.getElementById("optOut");
  if (checkBox.checked == true){
    optOut = true
  } else {
    optOut = false;
  }
   var HowWillYouPayValue = null
  var HowWillYouPay = document.getElementsByName('HowWillYouPay');
  var MembershipCategoryDesiredValue = null
  var MembershipCategoryDesired= document.getElementsByName('MembershipCategoryDesired');
              
    for(let i = 0; i < HowWillYouPay.length; i++) {
          if(HowWillYouPay[i].checked)
          HowWillYouPayValue = HowWillYouPay[i].value;
      }
      for(let i = 0; i < MembershipCategoryDesired.length; i++) {
            if(MembershipCategoryDesired[i].checked)
            MembershipCategoryDesiredValue = MembershipCategoryDesired[i].value;
        }

    const data = {
      Id:props.passData.id,
      ExpiryDate: props.passData.expirydate,
      HowWillYouPay: HowWillYouPayValue,
      FirstName: document.getElementById('FirstName').value,
      LastName: document.getElementById('LastName').value,
      IowaLicenseNumber: document.getElementById('IowaLicenseNumber').value,
      InterpretingCredentials: document.getElementById('InterpretingCredentials').value,
      Address: document.getElementById('Address').value,
      // address1: document.getElementById('LastName').value,
      city: document.getElementById('city').value,
      state: document.getElementById('state').value,
      zipcode: document.getElementById('zipcode').value,
      emailAddress: document.getElementById('emailAddress').value,
      PreferredPhoneNumber: document.getElementById('PreferredPhoneNumber').value,
      //PreferredPhoneIsA: document.getElementById('LastName').value,
      MembershipCategoryDesired: MembershipCategoryDesiredValue,
      opt:optOut,
    }
 
    return new Promise((resolve, reject) => {
      // debugger 
     
      if(isEmpty(data.HowWillYouPay)== false && isEmpty(data.FirstName)== false && isEmpty(data.LastName)== false && isEmpty(data.IowaLicenseNumber)== false && isEmpty(data.Address)== false && isEmpty(data.city)== false && isEmpty(data.state)== false && isEmpty(data.emailAddress)== false && isEmpty(data.PreferredPhoneNumber)== false && isEmpty(data.MembershipCategoryDesired)== false){
      sessionStorage.setItem('returningMemberData', JSON.stringify(data))
      sessionStorage.setItem('PutData', 'PutData')
      window.location.pathname = '/MembershipCategory'
      }
      else{
        alert("Check all fields are filled")
      }
    })
  }

  return (
      <div
        className="card pageCard"
        style={{
          backgroundColor:
            membership[0].MembershipApplicationPageBackgroundColor,
        }}
      >
        <div className="container pageContainer">
       
          <h2
            class="membershipTitle"
            style={{
              fontFamily: dynamicNode.TitleFontFamily,
              fontSize: dynamicNode.TitleFontSize,
              fontWeight: dynamicNode.TitleFontWeight,
              textAlign: dynamicNode.TitleAlignment,
              color: dynamicNode.TitleFontColor,
            }}
          >
            {dynamicNode.TitleName}
          </h2>

          <form action="">
            {/* ************************* */}
            <div class="form-outline">
              <p>{membership[0].HowWillYouPay}</p>
              <input
                type="radio"
                name="HowWillYouPay"
                id="HowWillYouPay"
                required
                value={membership[0].IWillPayForMyOwnMembership}
              />
              &nbsp;
              <label htmlFor="HowWillYouPay">
                {membership[0].IWillPayForMyOwnMembership}
              </label>
              <br />
              <input
                type="radio"
                name="HowWillYouPay"
                id="HowWillYouPay"
                required
                value={membership[0].IRequestASponsoredMembership}
              />
              &nbsp;
              <label htmlFor="HowWillYouPay">
                {membership[0].IRequestASponsoredMembership}
              </label>
              <br />
              <input
                type="radio"
                name="HowWillYouPay"
                id="HowWillYouPay"
                required
                value={
                  membership[0].IWillPayForMyOwnAndPlusSponsorAnotherMembership
                }
              />
              &nbsp;
              <label htmlFor="HowWillYouPay">
                {membership[0].IWillPayForMyOwnAndPlusSponsorAnotherMembership}
              </label>
            </div>
            <br />
            {/************************** */}
            <label htmlFor="firstname">
              {' '}
              <b>{membership[0].firstName}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('firstName')}
              class="form-control"
              placeholder="Enter Your First name"
              name="FirstName"
              id="FirstName"
              required
              minlength="2"
              maxlength="20"
            />
            <br />
            <label htmlFor="lastname">
              {' '}
              <b>{membership[0].lastName}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('lastName')}
              class="form-control"
              placeholder="Enter Your Last name"
              name="LastName"
              id="LastName"
              required
              minlength="2"
              maxlength="20"
            />
            <br />
            <label htmlFor="licenseNumber">
              {' '}
              <b>{membership[0].IowaLicenseNumber} </b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('IowaLicenseNumber')}
              class="form-control"
              placeholder="Enter IOWA license number"
              name="IowaLicenseNumber"
              id="IowaLicenseNumber"
              required
              minlength="2"
              maxlength="15"
            />
            <br />
            <p style={{ fontSize: '15px' }}>{membership[0].longText1}</p>
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="InterpretingCredentials">
                {' '}
                <b>{membership[0].InterpretingCredentials}</b>
              </label>
              <br />
              <input
                type="text"
                {...register('InterpretingCredentials')}
                class="form-control"
                placeholder="Enter Interpreting Credentials"
                name="InterpretingCredentials"
                id="InterpretingCredentials"
                minlength="2"
                maxlength="30"
              />
              <br />
            </div>
            <div class="form-outline">
              <label htmlFor="Address1">
                {' '}
                <b>{membership[0].Address1}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="text"
                {...register('address')}
                class="form-control"
                placeholder="Enter your Address1"
                name="Address"
                id="Address"
                required
                minlength="2"
                maxlength="50"
              />
              <br />
            </div>
            <div class="form-outline">
              <label htmlFor="Address2">
                {' '}
                <b>{membership[0].Address2}</b>
              </label>
              <br />
              <input
                type="text"
                class="form-control"
                placeholder="Enter your Address2"
                name="address1"
                id="address1"
                minlength="2"
                maxlength="50"
              />
            </div>
            <br />
            {/* ************************* */}
            <label htmlFor="city">
              {' '}
              <b>{membership[0].city}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('city')}
              class="form-control"
              placeholder="Enter city"
              name="city"
              id="city"
              required
              minlength="2"
              maxlength="15"
            />
            <br />
            <label htmlFor="state">
              {' '}
              <b>{membership[0].state}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <select
            type="text"
            {...register('state')}
            class="form-control"
            placeholder="Enter state"
            name="state"
            id="state"
            required
            >
             <option hidden></option> 
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="District Of Columbia">District Of Columbia</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
            <br />
            <label htmlFor="zip">
              {' '}
              <b>{membership[0].ZIP}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('zipcode')}
              class="form-control"
              placeholder="Enter ZIP"
              name="zipcode"
              id="zipcode"
              required
              minlength="5"
              maxlength="5"
            />
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="email">
                {' '}
                <b>{membership[0].EmailAddress}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="email"
                {...register('email')}
                class="form-control"
                placeholder="Enter your email address"
                class="form-control"
                name="emailAddress"
                id="emailAddress"
                required
                disabled
                
              />
            </div>
            <br />
            <div class="form-outline">
              <label htmlFor="phoneNumber">
                {' '}
                <b>{membership[0].PhoneNumber}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="text"
                {...register('PhoneNumber')}
                class="form-control"
                inputmode="numeric"
                placeholder="Enter your Phone number"
                name="PreferredPhoneNumber"
                id="PreferredPhoneNumber"
                required
                minlength="10"
                maxlength="12"
              />
            </div>
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="preferredPhoneNumber">
                {' '}
                <b>{membership[0].PreferredPhoneIsA} </b>
                <span className="star">{star}</span>
              </label>
              <br />
              <select
                name="PreferredPhoneIsA"
                id="PreferredPhoneIsA"
                class="form-control preferredNumber"
                required
              >
                <option hidden></option>
                <option value={membership[0].mobileNumber}>
                  Mobile Number
                </option>
                <option value={membership[0].WorkNumber}>Work Number</option>
                <option value={membership[0].HomeNumber}>Home Number</option>
              </select>
            </div>
            <br />
            {/* ************************* */}
            <div class="form-outline">
            <label htmlFor="opt" style={{paddingRight:"20px"}}>
                {' '}
                <b>Opt-in to ISRID interpreter search  </b>
                <span className="star">{star}</span>
            </label>

            <label class="switch">
              <input type="checkbox" name='optOut' id="optOut" required {...register('opt')} />
              <span class="slider round"></span>
            </label>
            </div>
            {/* ************************* */}
            <div class="form-outline">
              <p>{membership[0].MembershipCategoryDesired}</p>
              <input
                type="radio"
                name="MembershipCategoryDesired"
                id="MembershipCategoryDesired"
                required
                value={membership[0].DualMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].DualMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                id="MembershipCategoryDesired"
                required
                value={membership[0].StateAffiliateMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].StateAffiliateMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                id="MembershipCategoryDesired"
                required
                value={membership[0].StudentMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].StudentMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                id="MembershipCategoryDesired"
                required
                value={membership[0].SupportingMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].SupportingMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                id="MembershipCategoryDesired"
                required
                value={membership[0].SupportingOrganization}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].SupportingOrganization}
              </label>
            </div>
            <br />
  
            {/* ************************* */}
            <p style={{ paddingBottom: '60px' }}>
              <ReactMarkdown source={membership[0].longText2} />
            </p>
            {/* ************************* */}
            {/* <div class="form-outline">
              <input
                type="text"
                class="form-control"
                ref={captchaRef1}
                name="captcha"
                placeholder="Enter Captcha"
                required
                style={{ width: '40%' }}
              />
              <LoadCanvasTemplate /> */}
            
            <div className="text-center mt-4">
            {/* <button type="button" class="button_green" onClick={createData}>CLICK HERE TO PROCEED</button> */}
              {/* <MDBBtn color="success"  outline type="submit" onClick={createData}>
              Click here to proceed <MDBIcon far icon="paper-plane" className="ml-2" />
              </MDBBtn> */}
              <button type="submit" class="btn btn-success membershipbtn" onClick={createData}
              style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"16px",
              width:"27%",}}>Click here to proceed</button>
            </div>
            {/* </div> */}
          </form>
        </div>
      </div>
  )
}

export default UpdateMembershipForm
