import React from 'react';
import NewMembershipForm from '../components/membershipApplication/NewMembershipShip';
import UpdateMembershipForm from '../components/membershipApplication/UpdateMembershipForm';
import Layout from '../components/layout';
import { Link } from 'gatsby'

const MembershipApplication = () => 
{
  const NewMembership = typeof window !== 'undefined' ?sessionStorage.getItem("NewMembership") : null
  const ReturningMembership = typeof window !== 'undefined' ? sessionStorage.getItem("ReturningMembership") : null
  const DataValue = typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('PersonValue')) : null
  console.log(NewMembership)
  console.log(ReturningMembership)
  console.log(DataValue)
  typeof window !== 'undefined' ?sessionStorage.clear() : null
   
  if(NewMembership == 'true'){
    return <Layout><NewMembershipForm /></Layout>
  }
  if(ReturningMembership == 'true'){
    //return <UpdateMembershipForm email={EmailId}/>
    return <Layout><UpdateMembershipForm passData={DataValue}/></Layout>
  }

  return (
  <Layout>
    <Link to="/home">
    {' '}
    <span style={{ fontSize: '25px' }}>←</span> Back to Home Page
    </Link>
  </Layout>

  )
}


export default MembershipApplication

