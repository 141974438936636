import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import './membershipapplication.css'
import {loadCaptchaEnginge,LoadCanvasTemplate,validateCaptcha} from "react-simple-captcha";
import { AXIOSLINK } from '../../utils/constants'
const axios = require('axios')
import Swal from 'sweetalert2'
import {
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import { isEmpty } from 'lodash'

const NewMembershipForm = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiMembershipApplication {
        nodes {
          MembershipApplicationPageBackgroundColor
          AbovePhoneNumberIs
          Address1
          Address2
          DualMember
          EmailAddress
          HomeNumber
          HowWillYouPay
          IRequestASponsoredMembership
          IWillPayForMyOwnAndPlusSponsorAnotherMembership
          IWillPayForMyOwnMembership
          InterpretingCredentials
          IowaLicenseNumber
          MembershipCategoryDesired
          PhoneNumber
          NewMember
          PreferredPhoneIsA
          ReturningMember
          StateAffiliateMember
          StudentMember
          SupportingMember
          SupportingOrganization
          TextOnly
          WhichAreYou
          WorkNumber
          ZIP
          city
          firstName
          lastName
          longText1
          longText2
          mobileNumber
          state
          video
          voiceAndText
          voiceonly
          MembershipApplicationTitle {
            TitleAlignment
            TitleFontColor
            TitleFontFamily
            TitleFontSize
            TitleFontWeight
            TitleName
          }
        }
      }
    }
  `)
  
  var membership = data.allStrapiMembershipApplication.nodes
  const dynamicNode = membership[0].MembershipApplicationTitle[0]
  const star = '*'
  const initialFormData = Object.freeze({
    WhichAreYou: '',
    HowWillYouPay: '',
    FirstName: '',
    LastName: '',
    IowaLicenseNumber: '',
    InterpretingCredentials: '',
    Address: '',
    address1: '',
    city: '',
    state: '',
    zipcode: '',
    emailAddress: '',
    PreferredPhoneNumber: '',
    PreferredPhoneIsA: '',
    MembershipCategoryDesired: '',
    optOut:'',
    filename:'',
  })
  const [formData, updateFormData] = React.useState(initialFormData)
  const handleChange = e => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    })
  }


//   const captchaRef1 = useRef(null)

//   const handleCaptcha1 = e => {
//     const captcha = captchaRef1.current.value
//     if (validateCaptcha(captcha)) {
//       return true
//     } else {
//       return false
//     }
//   }

//   useEffect(() => {
//     loadCaptchaEnginge(6)
//   })

  const createData = e => {
    e.preventDefault()
  
  var optOut = null
  var checkBox = document.getElementById("optOut");
  if (checkBox.checked == true){
    optOut = true
  } else {
    optOut = false;
  }
    

    const data = {
      HowWillYouPay: formData.HowWillYouPay,
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      IowaLicenseNumber: formData.IowaLicenseNumber,
      InterpretingCredentials: formData.InterpretingCredentials,
      Address: formData.Address,
      // address1: formData.address1,
      city: formData.city,
      state: formData.state,
      zipcode: formData.zipcode,
      emailAddress: formData.emailAddress,
      PreferredPhoneNumber: formData.PreferredPhoneNumber,
      //PreferredPhoneIsA: formData.PreferredPhoneIsA,
      MembershipCategoryDesired: formData.MembershipCategoryDesired,
      opt:optOut,

    }

    return new Promise((resolve, reject) => {
      // debugger
      console.log(data);
      
      
      if (isEmpty(data.HowWillYouPay)== false && isEmpty(data.FirstName)== false && isEmpty(data.LastName)== false && isEmpty(data.IowaLicenseNumber)== false  && isEmpty(data.Address)== false && isEmpty(data.city)== false && isEmpty(data.state)== false && isEmpty(data.emailAddress)== false && isEmpty(data.PreferredPhoneNumber)== false && isEmpty(data.MembershipCategoryDesired)== false) {
        axios
          .get(AXIOSLINK+'users', {
            params: { email: data.emailAddress },
          })
          .then(function(result) {
            console.log(result)
             if (result.data[0] == null) {
              sessionStorage.setItem('NewMemberData', JSON.stringify(data))
              sessionStorage.setItem('PostData', 'PostData')
              Swal.fire({
                title: ' Thank You',
                text: 'Your Membership form has been submitted. Please proceed to payment.',
                confirmButtonColor: '#00c851',
              }).then(response => {
                /* Read more about isConfirmed, isDenied below */
                if (response.isConfirmed) {
                  window.location.pathname = '/MembershipCategory'
                } 
              })
              
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Your Email already Exist!',
                confirmButtonColor: '#00c851',
              })
            }
          })
      }
      else{
        alert("Check all fields are filled")
      }
    })
  }

  return (
      <div
        className="card pageCard"
        style={{
          backgroundColor:
            membership[0].MembershipApplicationPageBackgroundColor,
        }}
      >
        <div className="container pageContainer">
          <h2
            class="membershipTitle"
            style={{
              fontFamily: dynamicNode.TitleFontFamily,
              fontSize: dynamicNode.TitleFontSize,
              fontWeight: dynamicNode.TitleFontWeight,
              textAlign: dynamicNode.TitleAlignment,
              color: dynamicNode.TitleFontColor,
            }}
          >
            {dynamicNode.TitleName}
          </h2>

          <form action="">
            {/* ************************* */}
            <div class="form-outline">
              <p>{membership[0].HowWillYouPay}</p>
              <input
                type="radio"
                name="HowWillYouPay"
                required
                onChange={handleChange}
                value={membership[0].IWillPayForMyOwnMembership}
              />
              &nbsp;
              <label htmlFor="HowWillYouPay">
                {membership[0].IWillPayForMyOwnMembership}
              </label>
              <br />
              <input
                type="radio"
                name="HowWillYouPay"
                required
                onChange={handleChange}
                value={membership[0].IRequestASponsoredMembership}
              />
              &nbsp;
              <label htmlFor="HowWillYouPay">
                {membership[0].IRequestASponsoredMembership}
              </label>
              <br />
              <input
                type="radio"
                name="HowWillYouPay"
                required
                onChange={handleChange}
                value={
                  membership[0].IWillPayForMyOwnAndPlusSponsorAnotherMembership
                }
              />
              &nbsp;
              <label htmlFor="HowWillYouPay">
                {membership[0].IWillPayForMyOwnAndPlusSponsorAnotherMembership}
              </label>
            </div>
            <br />
            {/************************** */}
            <label htmlFor="firstname">
              {' '}
              <b>{membership[0].firstName}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              class="form-control"
              placeholder="Enter Your First name"
              name="FirstName"
              required
              onChange={handleChange}
              minlength="2"
              maxlength="20"
            />
            <br />
            <label htmlFor="lastname">
              {' '}
              <b>{membership[0].lastName}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              class="form-control"
              placeholder="Enter Your Last name"
              name="LastName"
              required
              onChange={handleChange}
              minlength="2"
              maxlength="20"
            />
            <br />
            <label htmlFor="licenseNumber">
              {' '}
              <b>{membership[0].IowaLicenseNumber} </b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="number"
              class="form-control"
              placeholder="Enter IOWA license number"
              name="IowaLicenseNumber"
              required
              onChange={handleChange}
              minlength="2"
              maxlength="15"
            />
            <br />
            <p style={{ fontSize: '15px' }}>{membership[0].longText1}</p>
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="InterpretingCredentials">
                {' '}
                <b>{membership[0].InterpretingCredentials}</b>
                
              </label>
              <br />
              <input
                type="text"
                class="form-control"
                placeholder="Enter Interpreting Credentials"
                name="InterpretingCredentials"
                onChange={handleChange}
                minlength="2"
                maxlength="30"
              />
              <br />
            </div>
            <div class="form-outline">
              <label htmlFor="Address1">
                {' '}
                <b>{membership[0].Address1}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="text"
                class="form-control"
                placeholder="Enter your Address1"
                name="Address"
                required
                onChange={handleChange}
                minlength="2"
                maxlength="50"
              />
              <br />
            </div>
            <div class="form-outline">
              <label htmlFor="Address2">
                {' '}
                <b>{membership[0].Address2}</b>
              </label>
              <br />
              <input
                type="text"
                class="form-control"
                placeholder="Enter your Address2"
                name="address1"
                onChange={handleChange}
                minlength="2"
                maxlength="50"
              />
            </div>
            <br />
            {/* ************************* */}
            <label htmlFor="city">
              {' '}
              <b>{membership[0].city}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              class="form-control"
              placeholder="Enter city"
              name="city"
              required
              onChange={handleChange}
              minlength="2"
              maxlength="15"
            />
            <br />
            <label htmlFor="state">
              {' '}
              <b>{membership[0].state}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <select
            class="form-control"
            placeholder="Enter state"
            name="state"
            required
            onChange={handleChange}
          >
            <option hidden></option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="District Of Columbia">District Of Columbia</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
            <br />
            <label htmlFor="zip">
              {' '}
              <b>{membership[0].ZIP}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              class="form-control"
              placeholder="Enter ZIP"
              name="zipcode"
              required
              onChange={handleChange}
              minlength="5"
              maxlength="5"
            />
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="email">
                {' '}
                <b>{membership[0].EmailAddress}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="email"
                class="form-control"
                placeholder="Enter your email address"
                class="form-control"
                name="emailAddress"
                required
                onChange={handleChange}
                minlength="2"
                maxlength="50"
              />
            </div>
            <br />
            <div class="form-outline">
              <label htmlFor="phoneNumber">
                {' '}
                <b>{membership[0].PhoneNumber}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="text"
                class="form-control"
                inputmode="numeric"
                placeholder="Enter your Phone number"
                name="PreferredPhoneNumber"
                required
                onChange={handleChange}
                minlength="10"
                maxlength="12"
              />
            </div>
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="preferredPhoneNumber">
                {' '}
                <b>{membership[0].PreferredPhoneIsA} </b>
                <span className="star">{star}</span>
              </label>
              <br />
              <select
                name="PreferredPhoneIsA"
                class="form-control preferredNumber"
                required
                onChange={handleChange}
              >
                <option hidden></option>
                <option value={membership[0].mobileNumber}>Mobile Number</option>
                <option value={membership[0].WorkNumber}>Work Number</option>
                <option value={membership[0].HomeNumber}>Home Number</option>
              </select>
            </div>
            <br />
            {/* ************************* */}
            <div class="form-outline">
            <label htmlFor="opt" style={{paddingRight:"20px"}}>
                {' '}
                <b>Opt-in to ISRID interpreter search  </b>
                <span className="star">{star}</span>
            </label>

            <label class="switch">
              <input type="checkbox" name='optOut' id="optOut" required onChange={handleChange} checked/>
              <span class="slider round"></span>
            </label>
            </div>
            {/* ************************* */}
            <div class="form-outline">
              <p>{membership[0].MembershipCategoryDesired}</p>
              <input
                type="radio"
                name="MembershipCategoryDesired"
                required
                onChange={handleChange}
                value={membership[0].DualMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].DualMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                required
                onChange={handleChange}
                value={membership[0].StateAffiliateMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].StateAffiliateMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                required
                onChange={handleChange}
                value={membership[0].StudentMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].StudentMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                required
                onChange={handleChange}
                value={membership[0].SupportingMember}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].SupportingMember}
              </label>
              <br />
              <input
                type="radio"
                name="MembershipCategoryDesired"
                required
                onChange={handleChange}
                value={membership[0].SupportingOrganization}
              />
              &nbsp;
              <label htmlFor="MembershipCategoryDesired">
                {membership[0].SupportingOrganization}
              </label>
            </div>
            <br />
            {/* ************************* */}
        
            <p style={{ paddingBottom: '60px' }}>
              <ReactMarkdown source={membership[0].longText2} />
            </p>
            {/* ************************* */}
            {/* <div class="form-outline">
              <input
                type="text"
                class="form-control"
                ref={captchaRef1}
                name="captcha"
                placeholder="Enter Captcha"
                required
                style={{ width: '40%' }}
              />
              <LoadCanvasTemplate /> */}
              <div className="text-center mt-4">
              {/* <button type="button" class="button_green" onClick={createData}>CLICK HERE TO PROCEED</button> */}
              {/* <MDBBtn color="success"  outline type="submit"  onClick={createData}>
              Click here to proceed <MDBIcon far icon="paper-plane" className="ml-2" />
              </MDBBtn> */}
              <button type="submit" class="btn btn-success membershipbtn" onClick={createData}
              style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"16px",
              width:"27%",}}>Click here to proceed</button>
              </div>
            {/* </div> */}

            {/* ************************* */}
          </form>
        </div>
      </div>
  )
}

export default NewMembershipForm